import { Link } from "gatsby"
import _ from "lodash"
import React from "react"

function PostTags(props) {
  const { tags, style } = props
  return (
    <>
      {tags && tags.map(tag => (
        <Link
          className="tag--single"
          key={tag}
          style={style}
          to={`/tags/${_.kebabCase(tag)}`}
        >
          {tag}
        </Link>
      ))}
    </>
  )
}

export default PostTags
