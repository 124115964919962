import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import IconInline from '../IconInline/IconInline'
import PostTags from '../PostTags/PostTags'
import "./ArticleCard.scss"

const _ = require("lodash")

function ArticleCard(props) {
  const {
    frontMatter: { title, thumb, category, tags },
    fields: { slug },
    hideThumb
  } = props;

  function getImage() {
    if (thumb && !hideThumb) {
      return (
        <Link
          to={slug}
          className="card--article__link"
          aria-labelledby={`${_.kebabCase(title)}`}
        >
          <GatsbyImage
            className="card--article__image"
            alt=""
            image={thumb.childImageSharp.gatsbyImageData}
            width={500}
            height={500}
          />
        </Link>
      )
    }
    return ""
  }

  function getIcon(cat) {
    let shape = 'triangle'
    switch (cat) {
      case 'Illustration':
        shape = 'triangle'
        break
      case 'Design':
        shape = 'square'
        break
      case 'Development':
        shape = 'circle'
        break
      default:
        shape = 'triangle'
    }
    return (
      <IconInline 
        name={shape} 
        height={40} 
        style={{
          marginRight: "0.4ch",
          fontSize: "var(--scale--0)",
        }}
      />
    )
  }

  return (
    <div className={`card--article ${hideThumb || !thumb ? 'nothumb': 'thumb'}`}>
      {/* optional image wrapped in a link */}
      {getImage()}
      
      {/* text card */}
      <div className="card__text">
        <div className="card--article__meta">
          {/* category */}
          <Link
            className="card--article__category"
            to={`/categories/${_.kebabCase(category)}`}
            style={{ 
              fontSize: "var(--scale--0)",
              textDecoration: "none",
              display: "inline-block",
              marginRight: "0.6ch",
            }}
          >
            {getIcon(category)}
            {`${category}:`}
          </Link>
          {/* tags */}
          <PostTags
            tags={tags}
            style={{
              fontSize: "var(--scale--0)",
              textDecoration: "none",
              display: "inline-block",
            }}
          />
        </div>

        <Link className="card__title" to={slug} id={`${_.kebabCase(title)}`}>{title}</Link>
      </div>
    </div>
  )
}

export default ArticleCard

export const query = graphql`
  fragment IndexPostFragment on MarkdownRemark {
    frontmatter {
      category
      tags
      title
      thumb {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED, 
            width: 500, 
            height: 500, 
            quality: 80,
            formats: [AUTO]
          )
        }
      }
    }
    fields {
      slug
    }
  }
`;
